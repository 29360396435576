@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
  font-size: 24px !important;
  text-transform: uppercase;
  color: #2d3436;
  margin-bottom: 24px !important;
}

h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
  font-size: 18px !important;
  text-transform: uppercase;
  color: #2d3436;
  margin-bottom: 24px !important;
}

p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #2d3436;
  text-align: justify;
  margin-bottom: 0px !important;
}

li {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #2d3436;
  text-align: justify;
  margin-bottom: 0px !important;
}